@import "asset/variables.scss";

@font-face {
  font-family: 'ChaiGothic';
  src: url(ChaiGothicM.otf) format('opentype');
  font-weight: 500;
}

@font-face {
  font-family: 'ChaiGothic';
  src: url(ChaiGothicSB.otf) format('opentype');
  font-weight: 600;
}

@font-face {
  font-family: 'ChaiGothic';
  src: url(ChaiGothicEB.otf) format('opentype');
  font-weight: 800;
}

@font-face {
  font-family: 'ChaiGothic';
  src: url(ChaiGothicH.otf) format('opentype');
  font-weight: 900;
}

.spaceBottom1 {
  margin-bottom: $spacing1;
}
.spaceBottom2 {
  margin-bottom: $spacing2;
}
.spaceBottom3 {
  margin-bottom: $spacing3;
}

.noPadding {
  padding: 0;
}

.padding1 {
  padding-bottom: $spacing1;
}

.padding2 {
  padding-bottom: $spacing2;
}

.padding3 {
  padding-bottom: $spacing3;
}

.padding4 {
  padding-bottom: $spacing4;
}

.padding5 {
  padding-bottom: $spacing5;
}

.padding6 {
  padding-bottom: $spacing6;
}

.overflowHiden {
  overflow: hidden;
}

.cursorPointer {
  cursor: pointer;
}

.backgroundWhite {
  background-color: white;
}

.backgroundInput {
  background-color: #ededed;
}

.sticky {
  position: -webkit-sticky;
  position: sticky;
}