@import "asset/variables.scss";
@import "asset/common.scss";

.toggleButton {
  margin-right: 8px;
  margin-left: 0;
  border-radius: 16px !important;
  border-left: 1px solid rgba(0, 0, 0, 0.12) !important;
  height: 32px;
  min-width: 112px;
}

.conditionOperator {
  font-size: 14px;
  font-weight: bold;
  line-height: 40px;
  max-height: 40px;
  margin-left: 10px;
}

.conditionItem {
  font-size: 14px;
  line-height: 40px;
  margin-left: 8px;
}

.descriptionItem {
  font-size: 14px;
  line-height: 30px;
  padding-top: 5px;
}

.conditionDropdown {
  padding: 4px;
  padding-left: 12px;
}

.conditionGroupBox {
  border-radius: 5px;
  padding: 20px;
  background-color: white;
}

.cardHeader {
  position: relative;
  overflow: hidden;
}

.cardContent {
  width: 100%;
}

// web-app BoostUp.module.scss 파일하고 싱크
.padding {
  height: 24px;
}

.icon {
  display: inline-block;
  vertical-align: middle;
  margin-right: 6px;
  width: 24px;
  height: 24px;
}

.gray {
  color: $dark;
}

.schemeTable {
  width: 100%;
  border-spacing: 0 4px;
  border-collapse: separate;

  thead tr {
    background-color: $dark;
  }

  thead th {
    color: white;
    text-align: right;
    line-height: 20px;
    padding: 8px;
    width: 20%;
  }

  thead th:first-child {
    padding-left: 44px;
    text-align: left;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    width: 60%
  }

  thead th:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    padding-right: 18px;
  }

  tbody tr {
    height: 56px;
    text-align: center;
    background-color: #f0f0f0;
  }

  tbody tr:first-child {
    background-color: #f8f8f8;
  }

  tbody tr:last-child {
    background-color: #d6d6d6;
  }

  tbody td {
    line-height: 24px;
    padding: 0 8px;
    text-align: right;
  }

  tbody td:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    padding-left: 16px;
    text-align: left;
  }

  tbody td:last-child {
    font-family: Gotham-Bold;
    font-weight: bold;
    padding-right: 18px;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  th,
  td {
    border: none;
  }
}

.wrapper {
  padding: 24px 32px;
  text-align: left;

  background: $white;
  color: $black;
  font-family: $font-family;
  font-size: 14px;
  font-weight: $m;
  line-height: 1.5;
  letter-spacing: -0.5px;
  margin: 0 auto;
  padding: 32px $gutter;
  user-select: none;
  word-break: keep-all;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  h1,
  h2,
  h3,
  p,
  ul {
    margin: 0;
    padding: 0;
  }

  ul,
  li {
    list-style: none;
  }

  dl {
    margin: 0;
  }

  dd {
    margin: 0;
  }

  pre {
    font-size: 14px;
    overflow: auto;
    white-space: pre-wrap;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  img {
    max-width: 100%;
  }

  svg {
    fill: $black;
  }

  button,
  input,
  select {
    border: 0;
    border-radius: 0;
    outline: 0;
    margin: 0;
    padding: 0;
    background: transparent;
    color: inherit;
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    letter-spacing: inherit;
    line-height: inherit;

    &[type='submit'] {
      appearance: none;
    }
  }

  button {
    cursor: pointer;
  }

  select {
    appearance: none;
    cursor: pointer;
  }

  label {
    color: $dark;
    display: block;
    font-size: 12px;
    font-weight: 400;
  }

  ::-ms-clear,
  ::-ms-expand {
    display: none;
  }
}