@import "asset/variables.scss";
@import "asset/common.scss";

.conditionBackground {
  background-color: #ededed;
  border-radius: 5px;
  padding: 20px;
}

.operatorString {
  font-weight: bold;
  line-height: 40px;
  margin-left: 10px;
}