@import "asset/variables.scss";
@import "asset/common.scss";

.listItemWrapper {
  max-width: (250+($spacing1+$spacing2)*2);
  min-height: 250px;
}

.cardRoot {
  min-height: 250px;
  word-break: break-word;
}

.message {
  max-width: 250px;
  background-color: $yellow300;
  word-break: break-all;
  white-space: pre-wrap;
  padding: $spacing1;
}

.divider {
  margin-bottom: $spacing2;
  max-width: 600px;
}

.cardTitle {
  text-transform: capitalize;
  width: 208px;
}

.sectionHead {
  margin-top: 0;
}